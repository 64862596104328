import React from 'react';
import { Chip } from '@mui/material';
import grey from '@mui/material/colors/grey';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import teal from '@mui/material/colors/teal';
import { styled } from '@mui/material/styles';

const label = {
  open: 'Oczekuje na płatność',
  received: 'Zapłacono',
  processing: 'Zapłacono',
  completed: 'Zapłacono',
  cancelled: 'Anulowano',
  paid_externally: 'Opłacono poza systemem'
};

const StyledChip = styled(Chip)(({theme}) => ({
  "&.open": {
    backgroundColor: blue[200],
  },
  "&.received": {
    backgroundColor: green[200],
  },
  "&.processing": {
    backgroundColor: green[200],
  },
  "&.completed": {
    backgroundColor: green[200],
  },
  "&.cancelled": {
    backgroundColor: grey[300],
  },
  "&.paid_externally": {
    backgroundColor: teal[200],
  },
}));

const OrderStatusChip = ({ status }) => (
  <StyledChip
    label={label[status]}
    style={{ color: 'black', fontWeight: 600 }}
    className={status}
  />
);

export default OrderStatusChip;
