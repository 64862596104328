import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CopyIcon from '@mui/icons-material/FileCopy';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	IconButton,
	Paper,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import React, {Component, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
import * as actions from "../../../actions";
import BaseTooltip from '../../utils/BaseTooltip';
import ChipsInputCreatable from "../../utils/ChipsInputCreatable";
import formatAmount from '../../utils/formatAmount';
import OrderFeeTooltip from '../../utils/OrderFeeTooltip';
import OrderStatusChip from '../../utils/OrderStatusChip';
import TagChip from "../../utils/TagChip";
import UpdateDescriptionModal from './UpdateDescriptionModal';

const mapStateToProps = (state) => ({
	order: state.getOrder,
	tags: state.getTags,
});

const PREFIX = "Order";
const classes = {
	paper: `${PREFIX}-paper`,
	titleBox: `${PREFIX}-titleBox`,
	title: `${PREFIX}-title`,
	valueGridItem: `${PREFIX}-valueGridItem`,
	rowGridItem: `${PREFIX}-rowGridItem`,
	upper: `${PREFIX}-upper`,
	noDescription: `${PREFIX}-noDescription`,
}
const RootPaper = styled(Paper)(({theme}) => ({
	[`& .${classes.paper}`]: {
		height: '100%',
	}, [`& .${classes.titleBox}`]: {
		background: theme.palette.grey[100], color: '#000', fontWeight: 'bolder', padding: theme.spacing(2),
	}, [`& .${classes.title}`]: {
		fontWeight: 'bold',
	}, [`& .${classes.valueGridItem}`]: {
		wordWrap: 'break-word',
	}, [`& .${classes.rowGridItem}`]: {
		borderTop: '1px solid #e3e3e3',
		padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
		// padding: `${theme.spacing(10)}px 0`,
	}, [`& .${classes.upper}`]: {
		textTransform: 'uppercase',
	}, [`& .${classes.noDescription}`]: {
		opacity: 0.6,
	},
}));

const TagsList = ({tags}) => ((tags || []).map(({color, name}) => <TagChip
	key={name}
	label={name}
	bgCol={color}
/>))

const ManageOrderTags = ({order, actions, tags}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedTags, setSelectedTags] = useState(order.tags);
	const [updateRenewed, setUpdateRenewed] = useState('all');
	useEffect(() => actions.getAllTags(), [order.data.code, actions]);

	const handleSubmit = () => {
		actions.updateTagsForOrder(order.data.code, {
			tags: selectedTags.reduce((acc, curr) => ({...acc, [curr.name]: curr}), {}),
			updateRenewed,
		});

		setIsOpen(false);
	};

	const handleClose = () => {
		setIsOpen(false);
		setSelectedTags(order.tags);
	}

	const isRenewed = order?.orderRenewal?.status;

	return (
		<>
			<BaseTooltip title="Zarządzaj etykietami" placement="top">
				<IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
					<EditOutlinedIcon/>
				</IconButton>
			</BaseTooltip>
			<Dialog open={isOpen} onClose={handleClose} sx={{maxWidth: 'xs'}}>
				<DialogTitle>{`Zarządzaj etykietami płatności ${isRenewed ? 'cyklicznej' : ''}`}</DialogTitle>
				<DialogContent>
					<Box sx={{my: 2}}>
						<ChipsInputCreatable
							options={tags || []}
							values={selectedTags}
							setValues={setSelectedTags}
							isCreatable={true}
						/>
					</Box>
					{isRenewed && <FormControl>
						<FormLabel id="demo-radio-buttons-group-label">
							Aktualizuj
						</FormLabel>
						<RadioGroup
							aria-labelledby="demo-radio-buttons-group-label"
							defaultValue="no"
							name="radio-buttons-group"
							value={updateRenewed}
							onChange={(_, updateRenewed) => setUpdateRenewed(updateRenewed)}
						>
							<FormControlLabel
								value="no"
								control={<Radio/>}
								label="Tylko tą płatność"
							/>
							{order?.orderRenewal?.status === 'active' && (
								<FormControlLabel
									value="following"
									control={<Radio/>}
									label="Tą i kolejne płatności"
								/>
							)}
							<FormControlLabel
								value="all"
								control={<Radio/>}
								label="Wszystkie płatności"
							/>
						</RadioGroup>
					</FormControl>}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						variant="outlined"
						disableElevation
					>
						Anuluj
					</Button>
					<Button
						variant="contained"
						disableElevation
						color="primary"
						onClick={handleSubmit}
					>
						Zatwierdź
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}


class Order extends Component {
	constructor(props) {
		super(props);
		this.state = {
			copySuccess: false,
		};
	}

	copyLink() {
		let link = this.props.location.origin + '/pay/' + this.props.order.data.code;
		navigator.clipboard.writeText(link);
		this.setState({copySuccess: true});
		this.linkCopied();
	}

	linkCopied() {
		setTimeout(() => {
			this.setState({copySuccess: false});
		}, 3000);
	}

	renderDetails() {
		const {order} = this.props;

		const items = [{
			key: 'Otrzymasz', value: formatAmount(order.data.receiverAmount, order.data.currency), action: null,
		}, {
			key: 'Opłata', value: formatAmount(order.data.totalFeeAmount, order.data.currency), action: <OrderFeeTooltip/>,
		}, {
			key: 'Termin płatności', value: order.data.term, action: (<BaseTooltip
				title="Do tego czasu płatnik powinien uregulować należność"
				placement="top"
			>
				<IconButton size="small">
					<InfoOutlinedIcon/>
				</IconButton>
			</BaseTooltip>),
		}, {
			key: 'Tytuł płatności', value: order.data.title,
		}, {
			key: 'Opis płatności',
			value: order.data.description || (<i className={classes.noDescription}>brak opisu</i>),
			action: <UpdateDescriptionModal order={order}/>,
		}, {
			key: 'Status płatności', value: <OrderStatusChip status={order.data.status}/>,
		}, {
			key: 'Etykiety',
			value: <TagsList tags={order.tags}/>,
			// action: <UpdateDescriptionModal order={order}/>,
			action: <ManageOrderTags order={order} tags={this.props.tags} actions={this.props.actions}/>,
		}];

		return items.map(({key, value, action}) => (<Grid
			key={key}
			container
			item
			xs={12}
			alignItems="center"
			className={classes.rowGridItem}
		>
			<Grid
				item
				xs={5}
				className={`${classes.valueGridItem} ${classes.upper}`}
			>
				<b>{key}</b>
			</Grid>
			<Grid item xs={6} className={classes.valueGridItem}>
				<Box textAlign="right">{value}</Box>
			</Grid>
			<Grid item xs={1}>
				<Box textAlign="center">{action}</Box>
			</Grid>
		</Grid>));
	}

  render() {
    const { order } = this.props;
    return (
      <RootPaper className={classes.paper} variant='outlined'>
        <Box textAlign='center' className={classes.titleBox}>
          <Typography variant='body1' className={classes.title}>
            Szczegóły zlecenia #{order.data.code}
          </Typography>
        </Box>
        <Box p={2}>
          <Grid container spacing={2} justifyContent='space-between'>
            <Grid item xs={11}>
              <Box textAlign='right' className='amount'>
                <b>{formatAmount(order.data.amount, order.data.currency)}</b>
              </Box>
            </Grid>
            {this.renderDetails()}
          </Grid>
        </Box>
        {order.data.status === 'open' && (
          <Box p={1} textAlign='center'>
            <Typography paragraph>
              Utworzyliśmy płatność. <br/>Teraz możesz skopiować link do niej i wysłać płatnikowi.
            </Typography>
            <BaseTooltip
              open={this.state.copySuccess}
              placement='right'
              title={
                <>
                  <div>Skopiowałeś link do schowka.</div>
                  <div>
                    Wklej go do maila, SMSa lub komunikatora i wyślij
                    płatnikowi.
                  </div>
                </>
              }
            >
              <Button
                variant='contained'
                disableElevation
                startIcon={<CopyIcon />}
                onClick={this.copyLink.bind(this)}
              >
                {this.state.copySuccess ? 'Skopiowano' : 'Skopiuj link'}
              </Button>
            </BaseTooltip>
          </Box>
        )}
      </RootPaper>
    );
  }
}

const mapDispatchToProps = dispatch => ({actions: bindActionCreators(actions, dispatch)})

export default connect(mapStateToProps, mapDispatchToProps)(Order);
