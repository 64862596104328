import React from 'react';
import Layout from '../../../templates/layout';
import OrderDetails from '../../../components/user/orders/details';

const OrderPage = props => {
  return (
    <Layout >
      <OrderDetails code={props.code} location={props.location} />
    </Layout>
  );
};

export const Head = () => <title>Szczegóły zlecenia | Zapłatomat</title>;

export default OrderPage;
