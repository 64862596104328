import {Box, FormControl, FormGroup, MenuItem, Select} from "@mui/material";
import Autocomplete, {createFilterOptions} from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import * as React from "react";
import {useState} from "react";
import TagChip, {colors} from "./TagChip";

const filter = createFilterOptions();

const INITIAL_DIALOG_STATE = {
	name: '',
	color: 'grey',
}

const NAME_MAX_LENGTH = 50;

export const CreateTagDialog = ({
	                                open,
	                                handleClose,
	                                handleSubmit,
	                                dialogValue,
	                                setDialogValue,
	                                availableTagsNames,
	                                dialogTitle = 'Nowa etykieta',
	                                dialogContentText = 'Czy chcesz dodać nową etykietę?',
	                                submitButtonText = 'Dodaj',
                                }) => {
	const [errors, setErrors] = useState({name: null, color: null});
	const handleNameChange = (e) => {
		const name = e.target.value;

		let nameError = null;

		if (availableTagsNames.includes(name)) {
			nameError = 'Etykieta o wskazanej nazwie już istnieje';
		} else if (name.length > NAME_MAX_LENGTH) {
			nameError = `Nazwa nie może mieć więcej niż ${NAME_MAX_LENGTH} znaków`;
		}

		setErrors({
			...errors,
			name: nameError,
		});
		setDialogValue({...dialogValue, name});
	}
	const onClose = () => {
		setErrors({name: null, color: null});
		handleClose();
	}

	const isValid = Object.values(errors).every(err => !err);
	return (
		<Dialog open={open} onClose={onClose}>
			<form onSubmit={(params) => {
				if (isValid) handleSubmit(params);
			}}>
				<DialogTitle>{dialogTitle}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{dialogContentText}
					</DialogContentText>
					<FormGroup>
						<TextField
							value={dialogValue?.name || ''}
							onChange={handleNameChange}
							label="Nazwa"
							type="text"
							variant="outlined"
							margin="normal"
							required
							helperText={errors.name}
							error={!!errors.name}
						/>
						<FormControl fullWidth>
							<Select
								value={dialogValue?.color || 'grey'}
								label="Kolor"
								onChange={event => setDialogValue({...dialogValue, color: event.target.value})}
								sx={{
									backgroundColor: colors[dialogValue?.color || 'grey'][300],
									fontStyle: 'italic',
									color: 'rgba(0,0,0,.5)',
								}}
							>
								{Object.entries(colors).map(([name, value]) => (
									<MenuItem
										key={name}
										sx={{
											backgroundColor: value[300],
											color: 'transparent',
											'&:hover': {
												backgroundColor: value[200],
											},
										}}
										value={name}
									>Kolor etykiety</MenuItem>
								))}
							</Select>
						</FormControl>
					</FormGroup>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={onClose}>
						Anuluj
					</Button>
					<Button
						type="submit"
						disabled={!isValid}
					>
						{submitButtonText}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	)
}
const ChipsInputCreatable = ({values, setValues, options, isCreatable = false, inputProps = {}, ...props}) => {
	const [open, toggleOpen] = useState(false);

	const handleClose = () => {
		setDialogValue(INITIAL_DIALOG_STATE);
		toggleOpen(false);
	};

	const [dialogValue, setDialogValue] = useState(INITIAL_DIALOG_STATE);

	const handleSubmit = (event) => {
		event.preventDefault();
		setValues([...values, {
			name: dialogValue.name,
			color: dialogValue.color,
		}]);

		handleClose();
	};
	return (
		<>
			<Autocomplete
				value={values}
				multiple
				onChange={(event, state, type, newValue) => {
					if (type === "removeOption") {
						setValues(values.filter(tag => tag.name !== newValue.option.name));
						return;
					}

					const isSelected = values.some((value) => newValue?.option === value.name);
					const isOption = options.some(option => newValue?.option === option.name);
					if (type === 'createOption' && isCreatable && !isSelected) {
						if (isOption) {
							setValues([...values, options.find(o => o.name === newValue.option)]);
						} else {
							setTimeout(() => {
								toggleOpen(true);
								setDialogValue({
									name: newValue.option,
									color: 'grey',
								});
							});
						}
						return;
					}

					if (type === 'selectOption') {
						if (newValue.option.inputValue) {
							setTimeout(() => {
								toggleOpen(true);
								setDialogValue({
									name: newValue.option.name,
									color: 'grey',
								});
							});
							return;
						} else {
							setValues([...values, newValue.option]);
						}
					}

					if (type === 'clear') {
						setValues([]);
					}
				}}
				fullWidth
				options={options}
				getOptionLabel={(option) => {
					// e.g value selected with enter, right from the input
					if (typeof option === 'string') {
						return option;
					}
					if (option.inputValue) {
						return option.inputValue;
					}
					return option.name;
				}}

				filterOptions={(options, params) => {
					const filtered = filter(options, params).filter(option => !values.find(v => v.name === option.name));

					const {inputValue} = params;
					// Suggest the creation of a new value
					const isExisting = options.some((option) => inputValue === option.name);
					const isSelected = values.some(value => value.name === inputValue);
					if (inputValue !== '' && !isExisting && isCreatable && !isSelected) {
						filtered.push({
							inputValue: `Utwórz "${inputValue}"`,
							name: inputValue,
						});
					}

					return filtered;
				}}
				selectOnFocus
				clearOnBlur
				handleHomeEndKeys
				renderTags={(value, getTagProps) =>
					value.map((option, index) => (
						<TagChip bgCol={option.color} variant="outlined" label={option.name} {...getTagProps({index})} />
					))
				}
				freeSolo
				sx={{maxWidth: 'xs'}}
				renderInput={(params) => (
					<TextField
						{...params}
						label="Etykiety"
						{...inputProps}
					/>)
				}
				renderOption={(props, option) => <MenuItem {...props}>
					{option.inputValue ? <Box>{option.inputValue}</Box> : <TagChip bgCol={option.color} label={option.name}/>}
				</MenuItem>
				}
				{...props}
			/>
			{isCreatable && (
				<CreateTagDialog
					open={open}
					handleClose={handleClose}
					handleSubmit={handleSubmit}
					dialogValue={dialogValue}
					setDialogValue={setDialogValue}
					availableTagsNames={[...options, ...values].map(({name}) => name)}
				/>
			)}
		</>
	);
}

export default ChipsInputCreatable;
