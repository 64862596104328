import React from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FormComponent from '../../containers/FormComponent';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../../actions';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  TextField,
  Radio,
  RadioGroup,
  Grid,
} from '@mui/material';
import BaseTooltip from '../../utils/BaseTooltip';

const mapStateToProps = state => ({
  updatedOrder: state.updateOrderDescription,
});

const INITIAL_STATE = {
  open: false,
  description: '',
  deleteDescription: false,
  updateRenewed: 'no',
};

class UpdateDescriptionModal extends FormComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.updateDescription();
  }

  componentDidUpdate(prevProps) {
    const { updatedOrder, order } = this.props;
    if (updatedOrder !== prevProps.updatedOrder) {
      this.validateResponse(updatedOrder, () => {
        this.setState(INITIAL_STATE);
        this.props.actions.getOrder(order.data.code);
      });
    }

    if (order !== prevProps.order) {
      this.updateDescription();
    }
  }

  updateDescription() {
    const { description } = this.props.order.data;
    this.setState({ description });
  }

  handleSubmit = () => {
    const {description, deleteDescription, updateRenewed} = this.state;
    if (
      deleteDescription
      || description !== this.props.order.data.description
      || updateRenewed !== 'no'
    ) {
      this.props.actions.updateOrderDescription(this.props.order.data.code, {
        description: deleteDescription ? '' : description,
        updateRenewed,
      });
    } else {
      this.setState(INITIAL_STATE);
    }
  };

  canBeSubmitted = () => {
    const { description, deleteDescription } = this.state;
    return (description || deleteDescription) && description.length <= 190;
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  renderRenewalUpdateSetting = () => {
    return (
      <FormControl>
        <FormLabel id='demo-radio-buttons-group-label'>
          Aktualizuj
        </FormLabel>
        <RadioGroup
          aria-labelledby='demo-radio-buttons-group-label'
          defaultValue='no'
          name='radio-buttons-group'
          value={this.state.updateRenewed}
          onChange={(_, updateRenewed) => this.setState({updateRenewed})}
        >
          <FormControlLabel
            value='no'
            control={<Radio/>}
            label='Tylko tą płatność'
          />
          {this.props.order?.orderRenewal?.status === 'active' && (
            <FormControlLabel
              value='following'
              control={<Radio/>}
              label='Tą i kolejne płatności'
            />
          )}
          <FormControlLabel
            value='all'
            control={<Radio/>}
            label='Wszystkie płatności'
          />
        </RadioGroup>
      </FormControl>
    );
  }

  render() {
    const isRenewed = this.props.order?.orderRenewal?.status;

    return (
      <>
        <BaseTooltip title='Edytuj opis' placement='top'>
          <IconButton size='small' onClick={this.toggleOpen}>
            <EditOutlinedIcon />
          </IconButton>
        </BaseTooltip>
        <Dialog onClose={this.toggleOpen} open={this.state.open}>
          <form onSubmit={this.handleSubmitForm.bind(this, this.handleSubmit)}>
            <DialogTitle>
              Edycja opisu płatności {isRenewed ? 'cyklicznej' : ''}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} mt={2}>
                  <TextField
                    variant='outlined'
                    label='Opis'
                    placeholder='Opis'
                    type='text'
                    name='description'
                    fullWidth
                    disabled={this.state.deleteDescription}
                    value={this.state.description}
                    error={!!this.state.formErrors.description}
                    helperText={this.state.formErrors.description}
                    onChange={this.handleValueChange.bind(this)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={!this.props.order.data.description}
                        checked={this.state.deleteDescription}
                        onChange={this.handleCheckboxChange.bind(this)}
                        name='deleteDescription'
                        color='primary'
                      />
                    }
                    label='Usuń opis'
                  />
                </Grid>
                {isRenewed && (
                  <Grid item xs={12}>
                    {this.renderRenewalUpdateSetting()}
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.toggleOpen}>Anuluj</Button>
              <Button
                type='submit'
                color='primary'
                variant='contained'
                disableElevation
                disabled={!this.canBeSubmitted()}
              >
                Zmień
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateDescriptionModal);
