import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions';
import { bindActionCreators } from 'redux';
import BaseTooltip from './BaseTooltip';
import { IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import formatAmount from './formatAmount';

const mapStateToProps = state => ({
  fee: state.getOrdersFee,
});

class OrderFeeTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      minAmount: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.props.actions.getOrdersFee();
  }

  componentDidUpdate(prevProps) {
    const { fee } = this.props;
    if (fee !== prevProps.fee) {
      const { data } = fee;
      if (data) {
        this.setState({
          minAmount: formatAmount(data.min_amount),
          amount: Number(data.amount),
          loading: false,
        });
      } else {
        this.setState({ loading: false });
      }
    }
  }

  getTitle() {
    const { amount, minAmount } = this.state;
    if (amount) {
      return `Wysokość opłaty za usługę płatniczą świadczoną przez Fiberpay to ${amount}%,
      ale nie mniej niż ${minAmount} PLN`;
    } else {
      return 'Pobranie informacji o wysokości prowizji nie powiodło się';
    }
  }

  render() {
    return (
      <BaseTooltip
        disableHoverListener={this.state.loading}
        title={this.getTitle()}
        placement='top'
      >
        <IconButton size='small'>
          <InfoOutlinedIcon />
        </IconButton>
      </BaseTooltip>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderFeeTooltip);
