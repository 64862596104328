import React from 'react';
import {Box, Grid, Paper, Typography} from "@mui/material";
import {formatDuration} from 'date-fns'
import {pl} from "date-fns/locale";

const renewalStatusMap = {
  active: 'Aktywna',
  cancelled: 'Anulowano',
  completed: 'Zakończono',
}

const getRenewalEndDescription = (renewal) => {
  if (renewal.ordersCountLimit) {
    return `po utworzeniu ${renewal.ordersCountLimit} płatności`;
  }

  if (renewal.endsAt) {
    return renewal.endsAt;
  }

  return 'Bezterminowo';
}

const OrderRenewalDetails = ({renewal, children}) => {
  const detailsItems = [
    {
      key: 'Powtarzaj',
      value: `co ${formatDuration({[`${renewal.intervalType}s`]: renewal.intervalAmount}, {locale: pl})}`,
    },
    {
      key: 'Zakończenie',
      value: getRenewalEndDescription(renewal),
    },
    {
      key: 'Data utworzenia kolejnej płatności',
      value: renewal.nextRenewalAt || <i>nie dotyczy</i>,
    },
    {
      key: 'Status',
      value: renewalStatusMap[renewal.status],
    }
  ];


  return <Box>
    <Paper sx={{height: '100%'}} variant='outlined'>
      <Box textAlign='center' sx={{
        background: (theme) => theme.palette.grey[100],
        color: '#000',
        fontWeight: 'bolder',
        p: 2,
      }}>
        <Typography variant='body1' sx={{fontWeight: 'bold'}}>
          Płatnosć cykliczna #{renewal.code}
        </Typography>
      </Box>
      {detailsItems.map(({key, value}) => (
        <Grid
          key={key}
          container
          item
          xs={12}
          alignItems='center'
          sx={{borderTop: '1px solid #e3e3e3', pt: 2, px: 2, pb: 1}}
        >
          <Grid
            item
            xs={6}
            sx={{
              wordWrap: 'break-word',
              textTransform: 'uppercase',
            }}
          >
            <b>{key}</b>
          </Grid>
          <Grid item xs={6} sx={{wordWrap: 'break-word'}}>
            <Box textAlign='right'>{value}</Box>
          </Grid>
        </Grid>
      ))}
      {children}
    </Paper>
  </Box>
}

export default OrderRenewalDetails;
