import React, {useState} from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from '@mui/material';

const CancelOrderModal = ({open, handleClose, handleAccept, hasActiveRenewal}) => {
  const [cancelRenewal, setCancelRenewal] = useState(false);
  const toggleCancelRenewal = () => setCancelRenewal(shouldCancel => !shouldCancel);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Czy na pewno chcesz anulować to zlecenie?</DialogTitle>
      {hasActiveRenewal && (
        <DialogContent>
          <Typography>Powiązana płatność cykliczna jest aktywna, czy odwołać tworzenie kolejnych zleceń?</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={cancelRenewal}
                onChange={toggleCancelRenewal}
              />
            }
            label="Odwołaj tworzenie kolejnych zleceń"
          />
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose}
                variant="outlined-"
                color="primary"
        >
          Nie
        </Button>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => handleAccept(cancelRenewal)}
        >
          Tak
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelOrderModal;
