import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as actions from '../../../actions';
import Paginator from '../../Paginator';
import Order from './order';
import Notifications from './listNotifications';
import Link, {navigate} from 'gatsby-link';
import StyledTableCell from '../../utils/StyledTableCell';
import BasePageContent from '../../utils/BasePageContent';
import CancelOrderModal from './CancelOrderModal';
import MarkOrderAsPaidExternallyModal from './MarkOrderAsPaidExternallyModal';
import OrderRenewalDetails from "../../orderRenewals/OrderRenewalDetails";

const mapStateToProps = state => ({
  events: state.getOrderEventsHistory,
  order: state.getOrder,
  cancelOrder: state.cancelOrder,
  markOrderAsPaidExternally: state.markOrderAsPaidExternally,
});

const statuses = {
  // Send in blue statuses
  null: 'Niewysłane',
  not_sent: 'Niewysłane',
  request: 'Wysłane',
  click: 'Kliknięto',
  delivered: 'Dostarczone',
  unique_opened: 'Otwarte',
  opened: 'Otwarte',
  invalid_email: 'Niepoprawny Email',
  blocked: 'Zablokowane',
  error: 'Błąd',
  hard_bounce: 'Błąd',
  soft_bounce: 'Błąd',

  // Email labs statuses
  ok: 'Dostarczone',
  deferred: 'Oczekuje na dostarczenie',
  feedbackloop: 'feedbackloop',
  dropped: 'Błąd',
  spambounce: 'Błąd',
  unsubscribe: 'Błąd',
  hardbounce: 'Błąd',
  softbounce: 'Błąd',
  open_tracking: 'Otwarte',
  link_tracking: 'Kliknięto',
}

const formatHistoryItemMessage = item => {
  if (item.type === 'notification_status_changed') {
    const {status, message} = item.params;
    let ret = '';
    switch (status) {
      case 'delivered':
      case 'ok':
        ret = `${message} zostało dostarczone.`;
        break;

      case 'request':
      case 'deferred':
        ret = `${message} zostało wysłane.`;
        break;

      case 'unique_opened':
      case 'opened':
      case 'open_tracking':
        ret = `${message} zostało otwarte.`;
        break;

      case 'click':
      case 'link_tracking':
        ret = `${message} - link do płatności został kliknięty.`;
        break;

      default:
        ret = `${message} - ${statuses[status]}`;
        break;
    }

    return ret[0].toUpperCase() + ret.slice(1);
  }

  return item.message;
};

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      received: false,
      loading: true,
      modalOpen: false,
      modalOpenPaid: false,
    };
  }

  componentDidMount() {
    this.props.actions.getOrder(this.props.code);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.order !== this.props.order) {
      this.setState({ loading: false });
      if (this.props.order.data) {
        if (this.props.order.data.status === 'received') {
          this.setState({ received: true });
        }
      }
    }
    if (prevProps.cancelOrder !== this.props.cancelOrder) {
      if (this.props.cancelOrder.data) {
        this.props.actions.getOrder(this.props.code);
      } else {
        this.setState({ loading: false });
      }
    }
    if (prevProps.markOrderAsPaidExternally !== this.props.markOrderAsPaidExternally) {
      if (this.props.markOrderAsPaidExternally.data) {
        this.props.actions.getOrder(this.props.code);
      } else {
        this.setState({ loading: false });
      }
    }

  }

  handleGetOrder() {
    this.setState({ loading: true }, () =>
      this.props.actions.getOrder(this.props.code)
    );
  }

  callCancelOrder = (cancelRenewal) => {
    this.setState({ modalOpen: false, loading: true }, () =>
      this.props.actions.cancelOrder(this.props.code, { cancelRenewal })
    );
  };

  callMarkOrderAsPaidExternally= () => {
    this.setState({ modalOpenPaid: false, loading: true }, () =>
      this.props.actions.markOrderAsPaidExternally(this.props.code)
    );
  };

  cancelButton = () => {
    if (
      this.props.order &&
      this.props.order.data &&
      this.props.order.data.status === 'open'
    ) {
      return (
        <>
          <CancelOrderModal
            open={this.state.modalOpen}
            hasActiveRenewal={this.props.order.orderRenewal?.status === 'active'}
            handleClose={() => this.setState({ modalOpen: false })}
            handleAccept={this.callCancelOrder.bind(this)}
          />
          <Grid item>
            <Button
              variant='outlined'
              onClick={() => this.setState({ modalOpen: true })}
            >
              Anuluj płatność
            </Button>
          </Grid>
        </>
      );
    }
  };

  markOrderAsPaidExternallyButton = () => {
    if (
      this.props.order &&
      this.props.order.data &&
      (this.props.order.data.status === 'open' ||
       this.props.order.data.status ==='cancelled')

    ) {
      return (
        <>
          <MarkOrderAsPaidExternallyModal
            open={this.state.modalOpenPaid}
            handleClose={() => this.setState({ modalOpenPaid: false })}
            handleAccept={this.callMarkOrderAsPaidExternally.bind(this)}
          />
          <Grid item>
            <Button
              variant='outlined'
              onClick={() => this.setState({ modalOpenPaid: true })}
            >
              Oznacz jako opłacone
            </Button>
          </Grid>
        </>
      );
    }
  };

  render() {
    const { actions, code, events } = this.props;
    return (
      <BasePageContent loading={this.state.loading}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Alert severity='info'>
              <AlertTitle>Stale rozwijamy Zapłatomat!</AlertTitle>
              Już wkrótce nowe funkcje: wezwanie do zapłaty i kierowanie
              należności do windykacji.
            </Alert>
          </Grid>
          <Grid item xs={12} md={!this.state.received ? 6 : 12}>
            <Order location={this.props.location} />
          </Grid>

          {!this.state.received && (
            <Grid item xs={12} md={6}>
              <Notifications/>
            </Grid>
          )}

          {this.props.order?.orderRenewal && (
            <Grid item xs={12}>
              <Box py={2}>
                <OrderRenewalDetails renewal={this.props.order.orderRenewal}>
                  <Grid item textAlign='center' xs={12} sx={{p: 2}}>
                    <Link
                      to={`/order-renewals/${this.props.order.orderRenewal.code}`}
                    >
                      <Button
                        disableElevation
                        variant='contained'
                      >Szczegóły płatności cyklicznej</Button>
                    </Link>
                  </Grid>
                </OrderRenewalDetails>
              </Box>
            </Grid>
          )}

          <Grid item xs={12} md={12}>
            <Paginator
              resource={events}
              getResource={pagination =>
                actions.getOrderEventsHistory(code, pagination)
              }
              render={(events, renderPagination) => (
                <>
                  <Box textAlign='center'>
                    <Typography variant='h5' paragraph>
                      Historia zdarzeń zlecenia
                    </Typography>
                  </Box>
                  <TableContainer>
                    {events && (
                      <Table>
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Co się wydarzyło</StyledTableCell>
                            <StyledTableCell>Kiedy</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {events.map(ev => (
                            <TableRow hover key={ev.code}>
                              <TableCell>
                                {formatHistoryItemMessage(ev)}
                              </TableCell>
                              <TableCell>{ev.createdAt}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                    <Grid container justifyContent='center'>
                      <Grid item>
                        <Box py={2}>{renderPagination()}</Box>
                      </Grid>
                    </Grid>
                  </TableContainer>
                </>
              )}
            />
          </Grid>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Box py={2}>
                <Link className='link-inline' to='/orders' variant='body2'>
                  przejdź do historii zleceń
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Grid container justifyContent='center' spacing={1}>
            <Grid item>
              <Button
                variant='outlined'
                disableElevation
                color='primary'
                onClick={this.handleGetOrder.bind(this)}
              >
                Odśwież status
              </Button>
            </Grid>
            {this.cancelButton()}
            {this.markOrderAsPaidExternallyButton()}
            <Grid item>
              <Button
                variant='contained'
                disableElevation
                color='primary'
                onClick={() => navigate('/payment')}
              >
                Dodaj kolejne zlecenie
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </BasePageContent>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
