import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

const MarkOrderAsPaidExternallyModal = ({
  open,
  handleClose,
  handleAccept,
}) => {

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Czy na pewno chcesz oznaczyć zlecenie jako opłacone poza systemem?
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} variant='outlined-' color='primary'>
          Nie
        </Button>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          onClick={() => handleAccept()}
        >
          Tak
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MarkOrderAsPaidExternallyModal;
