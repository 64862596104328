import { TableCell } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({theme}) => ({
  head: {
    backgroundColor: theme.palette.grey[100],
    color: "#000",
    textTransform: 'uppercase',
    fontWeight: 'bolder'
  },
  body: {
    fontSize: 12,
  },
}));

export default StyledTableCell;