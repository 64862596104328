import {Pagination} from '@mui/material';
import React, {Component} from 'react';

export default class Paginator extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			loading: true,
			error: null,
			page: this.props.page || 1,
			pagesCount: 0,
		};
	}

	componentDidMount() {
		const {page} = this.state;
		this.props.getResource({page});
	}

	componentDidUpdate(prevProps, prevState) {
		const {resource, getResource, params} = this.props;

		if (resource && resource !== prevProps.resource) {
			this.handleResourceUpdated(resource);
		}

		const {page} = this.state;
		if (page !== prevState.page) {
			this.setState({loading: true})
			getResource({page});
		}

		if (JSON.stringify(params) !== JSON.stringify(prevProps.params)) {
			this.setState({loading: true})
			getResource({page, ...params});
		}
	}

	handleResourceUpdated(resource) {
		const {data, meta, reason} = resource;
		if (reason) {
			this.setState({error: reason, loading: false});
		} else {
			const pagesCount = meta.last_page > 1 ? meta.last_page : 0;
			const page = meta.current_page;
			this.setState({data, pagesCount, page, loading: false});
		}
	}

	handleChange = (e, page) => this.setState({page});

	renderPagination = () => {
		const {page, pagesCount} = this.state;
		if (!pagesCount) return <></>;
		return (
			<Pagination disabled={this.state.loading} count={pagesCount} page={page} onChange={this.handleChange}/>
		);
	};

	render() {
		return this.props.render(
			this.state.data,
			this.renderPagination,
			this.state.loading,
			this.state.error,
		);
	}
}
