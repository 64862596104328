import React from 'react';
import {Chip} from '@mui/material';
import grey from '@mui/material/colors/grey';
import blue from '@mui/material/colors/blue';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import BaseTooltip from './BaseTooltip';
import {styled} from '@mui/material/styles';

const label = {
  // Send in blue statuses
  null: 'Niewysłane',
  not_sent: 'Niewysłane',
  request: 'Wysłane',
  click: 'Kliknięto',
  delivered: 'Dostarczone',
  unique_opened: 'Otwarte',
  opened: 'Otwarte',
  invalid_email: 'Niepoprawny Email',
  blocked: 'Zablokowane',
  error: 'Błąd',
  hard_bounce: 'Hard Bounce',
  soft_bounce: 'Soft Bounce',

  // Email labs statuses
  deferred: 'Wysłane',
  feedbackloop: 'Błąd',
  dropped: 'Błąd',
  ok: 'Dostarczone',
  spambounce: 'Błąd',
  unsubscribe: 'Błąd',
  hardbounce: 'Błąd',
  softbounce: 'Błąd',
  open_tracking: 'Otwarte',
  link_tracking: 'Kliknięto',
};

const tooltip = {
  null: 'Przypomnienie zaplanowane do wysłania do płatnika',
  not_sent: 'Przypomnienie zaplanowane do wysłania do płatnika',
  request: 'Przypomnienie wysłane do płatnika',
  click: 'Płatnik kliknął link do płatności',
  delivered: 'Przypomnienie zostało dostarczone do skrzynki pocztowej płatnika',
  unique_opened: 'Płatnik otworzył email z przypomnieniem',
  opened: 'Płatnik otworzył email z przypomnieniem',
  invalid_email: 'Wskazany adres email płatnika jest niepoprawny',
  blocked: 'Próba dostarczenia powiadomienia nie powiodła się',
  error: 'Próba dostarczenia powiadomienia nie powiodła się',
  hard_bounce: 'Próba dostarczenia powiadomienia nie powiodła się',
  soft_bounce: 'Próba dostarczenia powiadomienia nie powiodła się',

  deferred: 'Przypomnienie zaplanowane do wysłania do płatnika',
  feedbackloop: 'Próba dostarczenia powiadomienia nie powiodła się',
  dropped: 'Próba dostarczenia powiadomienia nie powiodła się',
  ok: 'Przypomnienie zostało dostarczone do skrzynki pocztowej płatnika',
  spambounce: 'Próba dostarczenia powiadomienia nie powiodła się',
  unsubscribe: 'Próba dostarczenia powiadomienia nie powiodła się',
  hardbounce: 'Próba dostarczenia powiadomienia nie powiodła się',
  softbounce: 'Próba dostarczenia powiadomienia nie powiodła się',
  open_tracking: 'Płatnik otworzył email z przypomnieniem',
  link_tracking: 'Płatnik kliknął link do płatności',
};

const bgGray = {backgroundColor: grey[100]};
const bgLightBlue = {backgroundColor: blue[100]};
const bgBlue = {backgroundColor: blue[200]};
const bgLightRed = {backgroundColor: red[100]};
const bgRed = {backgroundColor: red[200]};
const bgLightGreen = {backgroundColor: green[100]};
const bgGreen = {backgroundColor: green[200]};

const StyledChip = styled(Chip)(({theme}) => ({
  [theme.breakpoints.down('sm')]: {
    fontSize: 'x-small',
    '& span': {
      whiteSpace: 'normal',
    },
  },
  cursor: 'pointer',
  "&.span": {
    whiteSpace: 'normal',
  },
  "&.null": bgGray,
  "&.not_sent": bgGray,
  "&.request": bgLightBlue,
  "&.click": bgGreen,
  "&.delivered": bgBlue,
  "&.unique_opened": bgLightGreen,
  "&.opened": bgLightGreen,
  "&.invalid_email": bgLightRed,
  "&.error": bgRed,
  "&.hard_bounce": bgRed,
  "&.soft_bounce": bgLightRed,

  "&.deferred": bgGray,
  "&.feedbackloop": bgRed,
  "&.dropped": bgRed,
  "&.ok": bgBlue,
  "&.spambounce": bgRed,
  "&.unsubscribe": bgRed,
  "&.hardbounce": bgRed,
  "&.softbounce": bgLightRed,
  "&.open_tracking": bgGreen,
  "&.link_tracking": bgGreen,
}));

const NotificationStatusChip = ({ status }) => (
  <BaseTooltip title={tooltip[status]} placement='top'>
    <StyledChip
      label={label[status]}
      style={{ color: 'black', fontWeight: 600 }}
      className={`${status}`}
      size='small'
    />
  </BaseTooltip>
);

export default NotificationStatusChip;
