import React, {Component} from 'react';
import {connect} from 'react-redux';
import {isBefore, startOfDay} from 'date-fns';
import {
  Box,
  Button,
  Paper,
  Grid,
  Typography,
  IconButton, Card, CardContent, CardActions,
} from '@mui/material';
import {
  InfoOutlined,
  NotificationsNoneOutlined,
  NotificationsOffOutlined,
} from '@mui/icons-material';
import {navigate} from 'gatsby';
import NotificationPreview from '../../NotificationPreview';
import BaseTooltip from '../../utils/BaseTooltip';
import NotificationStatusChip from '../../utils/NotificationStatusChip';
import {styled} from '@mui/material/styles';

function mapStateToProps(state) {
  return {
    order: state.getOrder,
  };
}

const PREFIX = 'ListNotifications';
const classes = {
  paper: `${PREFIX}-paper`,
  titleBox: `${PREFIX}-titleBox`,
  title: `${PREFIX}-title`,
  gridItem: `${PREFIX}-gridItem`,
  upper: `${PREFIX}-upper`,
  breakWord: `${PREFIX}-breakWord`,
};
const RootPaper = styled(Paper)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    height: '100%',
  },
  [`& .${classes.titleBox}`]: {
    background: theme.palette.grey[100],
    color: '#000',
    fontWeight: 'bolder',
    padding: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    fontWeight: 'bold',
  },
  [`& .${classes.gridItem}`]: {
    borderBottom: '1px solid #e3e3e3',
  },
  [`& .${classes.upper}`]: {
    textTransform: 'uppercase',
  },
  [`& .${classes.breakWord}`]: {
    wordWrap: 'break-word',
  },
}));

class ListNotifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewOpen: false,
      previewTemplate: '',
    };
  }

  navigateToNotifications = () => {
    const { code } = this.props.order.data;
    navigate(`/notifications/${code}`);
  };

  openPreview = template => {
    this.setState({ previewOpen: true, previewTemplate: template });
  };

  closePreview = () => {
    this.setState({ previewOpen: false, previewTemplate: '' });
  };

  renderDebtor = () => {
    const {debtor} = this.props?.order;
    return <Grid container item xs={12} justifyContent='flex-start'>
      <Card sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <CardContent>
          <Typography color="text.secondary" gutterBottom>
            Płatnik
          </Typography>
          <Typography variant="body2">
            {debtor.fullNameAndEmail}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" onClick={() => navigate(`/payers/${debtor.code}`)}>Szczegóły płatnika</Button>
        </CardActions>
      </Card>
    </Grid>
  };

  renderPlan = () => {
    const { order } = this.props;

    return order.notificationsPlan.plan.map(
      ({ message, date, template, messageStatus }, i) => (
        <Grid
          key={i}
          container
          item
          alignItems='center'
          className={classes.gridItem}
        >
          <Grid item xs={2.9} className={classes.upper}>
            {`${date}:`}
          </Grid>
          <Grid item xs={4}>
            <Box textAlign={'left'} justifyContent='center'>
              {message}
            </Box>
          </Grid>
          <Grid item xs={4.1} className={classes.upper}>
            <Box textAlign='center'>
              <NotificationStatusChip status={messageStatus} />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box textAlign='center'>
              <BaseTooltip title='Podgląd wiadomości' placement='top'>
                <IconButton
                  size='small'
                  onClick={() => this.openPreview(template)}
                >
                  <InfoOutlined />
                </IconButton>
              </BaseTooltip>
            </Box>
          </Grid>
        </Grid>
      )
    );
  };

  canNotificationsBeCreated = () => {
    const { term, status } = this.props.order.data;
    const today = startOfDay(new Date());
    const beforeToday = isBefore(new Date(term), today);

    return status === 'open' && !beforeToday;
  };

  renderActionButton = () => {
    const { order } = this.props;
    const plan = order.notificationsPlan?.plan;

    const disabled = !this.canNotificationsBeCreated();

    return (
      <Grid container item spacing={1} justifyContent='center'>
        <Grid item>
          <Button
            variant='outlined'
            color='primary'
            onClick={this.navigateToNotifications}
            disabled={disabled}
            endIcon={
              !disabled ? (
                <NotificationsNoneOutlined />
              ) : (
                <NotificationsOffOutlined />
              )
            }
          >
            {!!plan ? 'Edytuj' : 'Ustaw'} przypomnienia
          </Button>
        </Grid>
        {disabled && (
          <Grid item>
            Nie można ustawić przypomnień, ponieważ termin płatności już minął
          </Grid>
        )}
      </Grid>
    );
  };

  render() {
    const { order } = this.props;
    const plan = order.notificationsPlan?.plan;

    const { previewOpen, previewTemplate } = this.state;

    return (
      <RootPaper className={classes.paper} variant='outlined'>
        <NotificationPreview
          open={previewOpen}
          template={previewTemplate}
          handleClose={this.closePreview}
          orderCode={order.data.code}
        />
        <Box textAlign='center' className={classes.titleBox}>
          <Typography variant='body1' className={classes.title}>
            {plan
              ? 'Plan przypomnień'
              : 'Brak ustawionych przypomnień dla tego zlecenia'}
          </Typography>
        </Box>
        <Box className={classes.notifications}>
          <Box p={2}>
            <Grid container spacing={2} justifyContent='space-between'>
              {order.debtor && this.renderDebtor()}
              {plan && this.renderPlan()}
              {this.renderActionButton()}
            </Grid>
          </Box>
        </Box>
      </RootPaper>
    );
  }
}

export default connect(mapStateToProps)(ListNotifications);
