import {Chip} from "@mui/material";
import {blue, cyan, grey, indigo, lime, pink, purple, red, teal} from "@mui/material/colors";
import React from 'react';

export const colors = {grey, red, purple, cyan, blue, pink, lime, teal, indigo};

const TagChip = ({bgCol, ...props}) => (
	<Chip
		{...props}
		sx={{
			mb: 1,
			ml: 1,
			backgroundColor: bgCol ? colors[bgCol][300] : '',
			color: theme => bgCol ? theme.palette.getContrastText(colors[bgCol][300]) : 'inherit',
		}}
	/>
)

export default TagChip;